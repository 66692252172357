import React, { useState } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as copy from 'copy-to-clipboard';

const ContactItem = ({data, key}) => {
    const [show, setShow] = useState(false)

    const handleCopyToClipboard = (email, setShow) => {
        console.log(email)
        copy(email)
        setShow(true)
        setTimeout(function() { 
            setShow(false) 
        }, 2000)
    }

    return (
        <>
            <Row className="align-items-top justify-content-center">
                <Col className="col-md-auto d-flex justify-content-center col-12 my-4 my-md-0">
                    {data.avatar && 
                        <GatsbyImage 
                            imgStyle={{
                                objectFit: 'contain',
                                maxHeight: '80px'
                            }} 
                            image={ getImage(data.avatar) } 
                            alt={data.name} />}
                </Col>
                <Col className="col-md-auto text-center text-md-left position-relative">
                    <dl>
                        <dd>
                            <div className="font-size-5"><i>{data.department}</i></div>
                            <div className="contact-name  font-size-5">
                                {data.name}
                            </div>
                            <div className="contact-company font-size-4">
                                {data.company}
                            </div>
                            <div className="d-flex justify-content-center">
                                <a
                                    title={`Send e-mail to ${data.name}`}
                                    target="_blank"
                                    href={`mailto:${data.email}?subject=${data.subject}&body=${data.body}`}
                                    className="font-size-4"
                                >
                                    {data.email}
                                </a>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-copy-${key}`}>
                                            { show ?
                                                <span>Copied</span> :
                                                <span>Copy to clipboard</span>
                                            }
                                        </Tooltip>
                                    }
                                >
                                    <a href="#"
                                        className="d-inline-block text-black ml-6"
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            handleCopyToClipboard(data.email, setShow)
                                        }}>
                                        <i className="fa fa-copy"></i>
                                    </a>
                                </OverlayTrigger>
                            </div>
                        </dd>
                    </dl>
                </Col>
            </Row>
        </>
    )
}

export default ContactItem