import React from "react";
import { graphql } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import SEO from "../components/seo";
import BackButton from "../components/Core/BackButton";
import PageTitle from "../components/Core/PageTitle";
import ContactItem from "../components/Contact/ContactItem";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import NoAuthRedirect from "../components/Core/NoAuthRedirect";

const Content = styled.div`
    $ a:hover {
        color: silver;
    }
`

const Contact = ({data, location}) => {
    const headerConfig = {
        paddingBottom: 10,
        variant: 'minimal'
    };

    const {content} = data.allSharePointPageContentList

    const contacts = [
        {
            department: "Human resources",
            name: "Talent & Culture Team",
            company: "Cofra Holding",
            email: "people@cofraholding.com",
            avatar: data.avatarIT,
            subject: "Question about COFRA Careers portal",
            body: "Hello, I have a question about the COFRA Careers portal."
        },
        {
            department: "Technical support",
            name: "IT service desk",
            company: "Cofra Holding",
            email: "itservices@cofraholding.com",
            avatar: data.avatarIT,
            subject: "Technical question about COFRA Careers portal",
            body: "Hello, I have a technical question about the COFRA Careers portal."
        }
    ]

    return (
        <>  
            <UnauthenticatedTemplate>
                <NoAuthRedirect location={location} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper headerConfig={headerConfig}>
                    <SEO title="Contact" />
                    <Content>
                        <Container className="justify-content-left">
                            <BackButton />
                            <PageTitle 
                                title="Contact"
                                subtitle="Don't hesitate to reach out. We are here to help."
                            />
                            <Container className="mt-10">
                                <Row className="justify-content-center">
                                    { contacts.map((data,i) => (
                                        <Col key={i} className="col-lg-6 col-12 mb-20">
                                            <ContactItem data={data} key={i} />
                                        </Col>
                                    )) }
                                </Row>
                            </Container>
                        </Container>
                    </Content>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default Contact;

export const query = graphql`
    {
        allSharePointPageContentList( filter: {data: {fields: {Title: {eq: "contact"}}}}) 
        {
            content: nodes {
                data {
                    fields {
                        Section
                        Content
                    }
                }
            }
        }
        avatarIT: file(relativePath: {eq: "logo_cofra_square_black.png"}) {
            childImageSharp {
                gatsbyImageData (
                    height: 80
                    placeholder: NONE
                    formats: [AUTO, WEBP],
                    layout: CONSTRAINED
               )
            }
        }
    }
`